::-webkit-scrollbar {
  background-color: #191a1c;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #353566;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #606fce;
}
::-webkit-scrollbar-thumb:active {
  background-color: #616fc7;
}

#root {
  min-height: 100vh;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.loading {
  position: absolute;
  max-width: 512px;
  max-height: 512px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #222325;
  border: 2px dashed #5d6fd3;
  color: white;
}

.loading img {
  width: 32px;
  height: 32px;
  background-size: cover;
}

.header {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  border-bottom: 2px solid #616fc7;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #222325;
  box-sizing: border-box;
}

.header .title {
  font-size: 26px;
  padding: 0 20px;
  min-width: 200px;
  box-sizing: border-box;
  color: #606fce;
  text-shadow: 1px 1px #5fcde4;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.header .title img {
  margin-top: 2px;
}

.nav {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  height: 36px;
  padding: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: #a4a3a1;
  box-sizing: border-box;
  min-width: 100px;
  border-radius: 2px;
}

.nav a:hover {
  color: white;
  font-weight: bold;
}

.nav a.active {
  background-color: #191a1c;
  border: 2px solid #616fc7;
  border-bottom: 2px solid #191a1c;
  color: #69c9df;
  font-weight: bold;
}


.content {
  z-index: 0;
  min-height: 100vh;
}

.tutorial {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.tutorial .category {
  width: 200px;
  border-right: 2px solid #606fce;
  position: fixed;
  top: 40px;
  bottom: 0;
  overflow: auto;
  padding: 10px 0;
  z-index: 100;
  background-color: #1d1e20;
  box-sizing: border-box;
}

.tutorial .category .category-item {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
  height: 24px;
  color: #e6e5e1;
}

.tutorial .category .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 4px;
  height: 32px;
  font-size: 14px;
  text-decoration: none;
  color: #a4a3a1;
  box-sizing: border-box;
  text-align: left;
  padding-left: 20px;
}

.tutorial .category a:hover {
  background-color: #222035;
  color: white;
  font-weight: bold;
}

.tutorial .category .item.active {
  background: #252154;
  border-top: 2px solid #8fb8d4;
  border-bottom: 2px solid #8fb8d4;
  color: #69c9df;
  font-weight: bold;
  
}

.tutorial .info {
  flex: 1;
  padding-left: 200px;
  margin-top: 40px;
}

.tutorial .info .gif {
  margin-top: 20px;
  position: relative;
  min-height: 100%;
}

.gif-frames {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.gif-frames .bar {
  width: 516px;
  height: 20px;
  border: 2px solid #5d6fd3;
  box-sizing: border-box;
  position: relative;
}

.gif-frames .cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 16px;
  background-color:  #5fcde4;
  box-sizing: border-box;
  border-top: 1px solid #e2ffff;
  border-left: 1px solid #e2ffff;
}

.gif-frame {
  width: 10px;
  height: 16px;
  border: 2px solid #5d6fd3;
  border-right: none;
  user-select: none;
}

.gif-frame:last-child {
  border-right: 2px solid #5d6fd3;
}

.gif-frame.hoverable:hover {
  background-color: #555055;
}

.gif-frame.active {
  background-color: #5fcde4 !important;
}

.gif-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;

  image-rendering: pixelated;
  image-rendering: crisp-edges;
  /* color: #9b9589; */
}
.gif-control span, .gif-control a {
  /* font-size: 24px; */
  width: 32px;
  height: 32px;
  margin-right: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  /* color: #9b9589; */
  background: no-repeat url(./assets/gif_control_ui.png) var(--p) 0/auto 64px;
  /* background-position: var(--p) 0; */
}

.gif-control a.download {
  margin-left: 10px;
}

.gif-control span:hover,.gif-control a:hover {
  background-color: #222035;
  background-position: var(--p) -32px;
}

.authors {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 768px;
  min-height: 500px;
  margin: 0 auto;
  gap: 20px;
}

.author {
  width: 260px;
  height: 400px;
  border: 2px solid #a4a3a1;
  border-radius: 2px;
  background-color: #222325;
}

/* .author:hover {
  border: 2px solid #5d6fd3;
  cursor: pointer;
} */

.author .cover {
  width: 100%;
  height: 160px;
  position: relative;
}

.author .avatar {
  width: 56px;
  height: 56px;
  border: 2px solid #a4a3a1;
  border-radius: 2px;
  background-size: cover;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translate(-50%, 0);
}

.author .name {
  display: block;
  margin-top: 36px;
  color: white;
}

.author .desc {
  padding: 8px;
  color: #aaa3a1
}

.author a {
  color: #616fc7;
}

.author a:hover {
  color: #69c9df;
}

.author.pedro .cover {
  background-image: url('./assets/cover-pedro.png');
  background-size: cover;
  background-position: center;
}

.author.pedro .avatar {
  background-image: url('./assets//avatar-pedro.jpeg');
}

.author.slynyrd .cover {
  background-image: url('./assets/cover-slynyrd.png');
  background-size: cover;
  background-position: center;
}

.author.slynyrd .avatar {
  background-image: url('./assets//avatar-slynyrd.png');
}

.author.luke .cover {
  background-image: url('./assets/cover-luke.jpeg');
  background-size: cover;
  background-position: center;
}

.author.luke .avatar {
  background-image: url('./assets//avatar-luke.png');
}